html {
  font-size: 17px;
  background-color: darkgrey;
}

body {
  font-family: "Nunito", sans-serif;
}

p {
  font-size: 2rem;
  margin-bottom: -10px !important;
}

small {
  font-weight: lighter;
  color: gray;
  margin: 0;
}

.flex {
  padding-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.root {
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.menuitem {
  margin: 2vh auto;
}

.App {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 2vh auto;
}

.widget {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}


.Table {
  border-radius: 0.5rem;
  margin: 2vh auto;
}

.Expander {
  margin: 2vh, 2vh;
}

.CentralContainer {
  width: 100%;
  text-align:center;
}

.active {
  color: green;
}

.lineItem {
  display: block;
}
